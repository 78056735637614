<template>
  <div class="feiyi">
    <h2>{{ $t("非遗文化") }}</h2>
    <div style="height: 4rem">
      <!-- 视频 -->
      <!-- <video
        v-if="video"
        :src="video_url"
        type="video/mp4"
        controls="controls"
        autoplay="autoplay"
        ref="videoDom"
      ></video> -->
      <!-- 轮播图 -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in img_list" :key="index">
          <img class="banner" :src="item" alt="" />
        </van-swipe-item>
      </van-swipe>
      <!-- 按钮 -->
      <!-- <div class="btn">
        <van-button :icon="iconData" round color="#C4C4C4" @click="onVideo"
          >视频</van-button
        >
        <van-button round color="#C4C4C4" @click="onImg">图片</van-button>
      </div> -->
    </div>
    <h3>
      <i></i>
      {{ $t("趣赏非遗") }}
    </h3>
    <ul class="list">
      <li v-for="(item, index) in list" :key="index" @click="skip(item.title)">
        <img :src="item.src" alt="" />
        <span>{{ $t(item.title) }}</span>
      </li>
    </ul>
    <h3>
      <i></i>
      {{ $t("非遗项目") }}
    </h3>
    <ul class="classify">
      <li v-for="(item, index) in classify" :key="index">
        <div>
          <img :src="item.icon" alt="" />
          <p>{{ $t(item.title) }}</p>
        </div>
        <nut-button
          type="primary"
          shape="circle"
          small
          @click="look(item.title)"
          >{{ $t("查看") }}</nut-button
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
// 导入底部导航栏组件
export default {
  components: {},
  data() {
    return {
      img_list: [],
      type: null,

      iconData: "play", //视频按钮icon
      list: [
        {
          id: 1,
          title: "AR趣玩",
          src: require("../assets/images/AR.png"),
          href: "/#",
        },
        {
          id: 2,
          title: "线上展馆",
          src: require("../assets/images/XS.png"),
          href: "/#",
        },
        {
          id: 3,
          title: "游戏互动",
          src: require("../assets/images/YX.png"),
          href: "/#",
        },
        {
          id: 4,
          title: "云裳非遗",
          src: require("../assets/images/YS.png"),
          href: "/#",
        },
      ],
      classify: [
        {
          id: 1,
          title: "各派戏曲",
          href: "/#",
          icon: require("../assets/icon/feiyi/list1.png"),
        },
        {
          id: 2,
          title: "传统音乐",
          href: "/#",
          icon: require("../assets/icon/feiyi/list2.png"),
        },
        {
          id: 3,
          title: "传统美术",
          href: "/#",
          icon: require("../assets/icon/feiyi/list3.png"),
        },
        {
          id: 4,
          title: "民族舞蹈",
          href: "/#",
          icon: require("../assets/icon/feiyi/list4.png"),
        },
        {
          id: 5,
          title: "民间文学",
          href: "/#",
          icon: require("../assets/icon/feiyi/list5.png"),
        },
        {
          id: 6,
          title: "传统医学",
          href: "/#",
          icon: require("../assets/icon/feiyi/list6.png"),
        },
        {
          id: 7,
          title: "传统技艺",
          href: "/mine",
          icon: require("../assets/icon/feiyi/list7.png"),
        },
        {
          id: 8,
          title: "民俗文化",
          href: "/#",
          icon: require("../assets/icon/feiyi/list8.png"),
        },
        {
          id: 9,
          title: "曲艺",
          href: "/#",
          icon: require("../assets/icon/feiyi/list9.png"),
        },
        {
          id: 10,
          title: "民族服饰",
          href: "/#",
          icon: require("../assets/icon/feiyi/list10.png"),
        },
      ],
    };
  },
  created() {
    this.addImg();
  },
  methods: {
    ...mapMutations(["setLists", "skipType"]),
    //点击视频按钮
    // onVideo() {
    //   this.video = true;
    //   this.picture = false;
    //   if (this.iconData == "play") {
    //     this.iconData = "pause";
    //     // console.log(this.$refs["videoDom"]);
    //     setTimeout(() => {
    //       this.$refs.videoDom.play();
    //     }, 100);
    //   } else if (this.iconData == "pause") {
    //     this.iconData = "play";
    //     this.$refs.videoDom.pause();
    //   }
    // },
    //点击图片按钮
    // onImg() {
    //   this.video = false;
    //   this.picture = true;
    //   this.iconData = "play";
    // },
    skip(type) {
      this.skipType(type);
      this.$router.push("details");
    },
    look(type) {
      this.setLists(type);
      this.$router.push("/project");
    },
    // 获取banner数据
    addImg() {
      let params = null;
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      this.$axios
        .post("api/ich/getBanner", { abbreviation: abbreviation })
        .then((res) => {
          res.data.map((item, index) => {
            return this.img_list.push(item.imgUrl);
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.feiyi {
  margin-bottom: 8.5vh;
}
h2 {
  line-height: 0.8rem;
  font-size: 0.44rem;
  text-align: center;
}
/** 视频 */
video {
  width: 100%;
  height: 3.6rem;
  display: block;
}
/** 轮播图 */
.my-swipe .van-swipe-item {
  height: 3.6rem;
  color: #fff;
  line-height: 4rem;
  text-align: center;
  background-color: #fff;
}
.banner {
  width: 100%;
}
// .btn {
//   // position: absolute;
//   width: 2.2rem;
//   // left: 50%;
//   // top: 4rem;
//   // transform: translateX(-1.1rem);
//   margin: 0 auto;
//   text-align: center;
//   /deep/ .van-button {
//     width: 0.76rem;
//     height: 0.3rem;
//     font-size: 0.2rem;
//     margin: 0 0.1rem;
//     i {
//       transform: scale(0.7);
//       margin-right: -0.18rem;
//     }
//     span {
//       transform: scale(0.7);
//       white-space: nowrap;
//       color: #000;
//     }
//   }
// }
h3 {
  padding-left: 0.26rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.34rem;
  i {
    display: inline-block;
    margin-top: 0.08rem;
    width: 0.34rem;
    height: 0.24rem;
    background: url("../assets/icon/feiyi/h3.png") no-repeat center center;
    background-size: 100% 100%;
  }
}
.list {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  li {
    margin-left: 0.2rem;
    text-align: center;
    img {
      width: 1.62rem;
      height: 1.62rem;
      border-radius: 0.1rem;
    }
    span {
      display: inline-block;
      margin: 0.1rem 0;
      width: 1.6rem;
      color: #000;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
  li:last-child {
    margin-right: 0.2rem;
  }
}
.classify {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  li {
    margin: 0.1rem;
    width: 45%;
    height: 1.96rem;
    border-radius: 0.15rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.32rem;
    div {
      text-align: center;
      img {
        width: 0.92rem;
        height: 0.92rem;
        margin-bottom: 0.08rem;
      }
      p {
        font-size: 0.32rem;
        color: #000;
      }
    }
    /deep/ .nut-button {
      // width: 1.1rem;
      height: 0.42rem;
      font-size: 0.22rem;
      color: #fff;
    }
  }
  li:nth-child(1) {
    background: #efce95;
    /deep/ .nut-button {
      background: #d8a855;
    }
  }
  li:nth-child(2) {
    background: #ec9b9a;
    /deep/ .nut-button {
      background: #cf5857;
    }
  }
  li:nth-child(3) {
    background: #f4b8af;
    /deep/ .nut-button {
      background: #d17160;
    }
  }
  li:nth-child(4) {
    background: #8591c0;
    /deep/ .nut-button {
      background: #4b5a97;
    }
  }
  li:nth-child(5) {
    background: #9baef5;
    /deep/ .nut-button {
      background: #5f78d3;
    }
  }
  li:nth-child(6) {
    background: #eb8788;
    /deep/ .nut-button {
      background: #cf5857;
    }
  }
  li:nth-child(7) {
    background: #8dd4a7;
    /deep/ .nut-button {
      background: #4db673;
    }
  }
  li:nth-child(8) {
    background: #c280cd;
    /deep/ .nut-button {
      background: #975ba1;
    }
  }
  li:nth-child(9) {
    background: #f4b9af;
    /deep/ .nut-button {
      background: #c46f60;
    }
  }
  li:nth-child(10) {
    background: #8691c0;
    /deep/ .nut-button {
      background: #4f5fa2;
    }
  }
}
</style>
